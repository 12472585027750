import type {
  CommunicationToolOptions,
  DealNotesOptions
} from "@/models/common";
import type { ModalOptions } from "@/models/emails";
import { nextTick } from "vue";

export const autoLink = (
  text: string,
  options?: { target?: "_self" | "_blank" | "_parent" | "_top"; rel?: string }
) => {
  const target = options?.target || "_blank";
  const rel = options?.rel || "noopener noreferrer";
  // capture url patterns for either markdown style links or plain text urls
  const urlPattern =
    /(?:\[(.*?)\]\((.*?)\))|(?:^|\s)((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;
  return text.replace(urlPattern, (match, p1, p2, p3) => {
    const url = p3 || p2;
    const label = p1 || url;
    return `${
      p3 ? " " : ""
    }<a href="${url}" target="${target}" rel="${rel}">${label}</a>`;
  });
};

export const checkElementIsWithinViewport = (
  elementBounding: DOMRect | undefined,
  position: { x: number; y: number }
) => {
  if (!elementBounding) {
    return;
  }
  const OFFSET_X = 5;
  const OFFSET_Y = 1;

  let { x, y } = position;

  if (elementBounding.left < 0) {
    x = OFFSET_X;
  }
  if (elementBounding.top < 0) {
    y = OFFSET_Y;
  }
  if (elementBounding.right > window.innerWidth) {
    x = window.innerWidth - elementBounding.width - OFFSET_X;
  }
  if (elementBounding.bottom > window.innerHeight) {
    y = window.innerHeight - elementBounding.height - OFFSET_Y;
  }

  return { x, y };
};

export const ensurePositionWithinViewport = async (
  optionsToUpdate: ModalOptions | DealNotesOptions | CommunicationToolOptions,
  domRect?: DOMRect
) => {
  const { x, y } = (
    "x" in optionsToUpdate
      ? optionsToUpdate
      : { x: optionsToUpdate.left, y: optionsToUpdate.top }
  ) as {
    x: number;
    y: number;
  };
  if (!domRect) {
    return { x, y };
  }
  const newPosition = await nextTick(() =>
    checkElementIsWithinViewport(domRect, { x, y })
  );
  if (!newPosition) {
    return { x, y };
  }
  return {
    x: newPosition.x,
    y: newPosition.y
  };
};
